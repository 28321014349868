<template>
  <div class="d-flex flex-column flex-root h-100">
    <div
      class="d-flex flex-row-fluid flex-column bgi-size-cover bgi-position-center bgi-no-repeat p-10 p-sm-30 h-100"
      :style="{
        backgroundImage: 'url(/media/error/bg1.jpg)'
      }"
    >

    <div class="mt-5 ml-5">
      <!-- begin:: Content -->
      <h1
        class="font-weight-boldest text-dark-75 mt-15"
        style="font-size: 100px; display: block"
      >
        {{ this.$route.params.error || 'Error' }}
      </h1>
      <p class="font-size-h3 font-weight-light mt-4">
        {{ $t(errorMsg) }}
      </p>
      <!-- end:: Content -->
    </div>

    </div>
  </div>
</template>

<style lang="scss">
.bgi-size-cover {
  background-size: cover;
}
</style>

<script>
export default {
  name: 'Error-1',
  mounted () {},
  computed: {
    backgroundImage () {
      return process.env.BASE_URL + 'media/error/bg1.jpg'
    },

    errorMsg () {
      switch (this.$route.params.error.toString()) {
        case '404': {
          return 'ERRORS.not_found'
        }

        case '403': {
          return 'ERRORS.forbidden'
        }

        default: {
          return 'ERRORS.general'
        }
      }
    }
  }
}
</script>
